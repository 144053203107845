import "./App.css";

function App() {
  return (
    <div className="min-h-screen bg-[#DFC77C]">
      <nav className="flex items-center justify-between h-[72px] px-16 text-white bg-white/5 backdrop-blur-[10px]">
        <div className="text-4xl">Galaxian</div>
        <div className="h-full flex items-center">
          <ul className="flex gap-x-9 text-2xl">
            <li className="uppercase">Twitter</li>
            <li className="uppercase">discord</li>
            <li className="uppercase">Magiceden</li>
          </ul>
          <div className="flex justify-center items-center w-[220px] h-10 ml-9 cursor-pointer">
            <img src="/images/btn.png" alt="mint" className="w-full h-full" />
          </div>
        </div>
      </nav>
      <div className="flex flex-col items-center pt-[125px] text-center text-white content">
        <div className="w-[490px] h-[118px]">
          <img
            src="/images/galaxian.png"
            alt="galaxian"
            className="w-full h-full"
          />
        </div>
        <div className="w-[696px] h-[93px] mt-[50px]">
          <img
            src="/images/slogan.png"
            alt="slogan"
            className="w-full h-full"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
